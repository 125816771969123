<template>
  <div id="nav">
  </div>
  <router-view/>
</template>

<style>
@font-face {
  font-family: "Helvetica";
  src: url("./assets/fonts/Helvetica/HelveticaRoman.ttf");
}

#app {
  font-family: 'Helvetica';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  padding: 10px;
}

#nav {
  padding: 5px;
}

#nav a {
  font-weight: bold;
  color: blue;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
