<template>
<div class="is-widescreen">
  <div class="columns">
    <!-- MOBILE MENU -->
    <a @click="toggleMobileMenu()" v-bind:class="[mobileMenuActive ? 'is-active' : '']" role="button" class="navbar-burger is-hidden-tablet" aria-label="menu" aria-expanded="false">
      <span class="has-background-white" aria-hidden="true"></span>
      <span class="has-background-white" aria-hidden="true"></span>
      <span class="has-background-white" aria-hidden="true"></span>
    </a>
    <aside v-if="mobileMenuActive" class="is-hidden-tablet mb-0 has-text-left pd-4 pb-2 column">
      <p class="is-size-4">
        <router-link id="hov" class="has-text-white" to="/about">à propos</router-link>
      </p>
     <div v-for="year in this.years" :key="year"><b class="column pt-4 pb-0 pl-0 is-size-4">{{year}}</b>
        <ul v-for="post in posts.slice().reverse()" :key="post.titre" class="mt-1">
          <li v-if="post.year == year">
            <a id="hov" class="has-text-white" @click="scrollMeTo(`${post.id}`)">{{post.titre}}
              <p class="pink" style="display: inline-block">{{post.sousTitre}}</p>
            </a>
          </li>
        </ul>
      </div>
      <div style="padding-top: 20px; margin: auto; width: 50%;">
        <a href="https://toutdoitdisparaitreclub.tumblr.com/" target="_blank">
          <img src="../assets/img/JigglyPuff01.gif" alt="tumblr link">
        </a>
      </div>
    </aside>
    <!-- FULL SIZE MENU -->
    <aside class="menu is-hidden-mobile mb-0 has-text-left">
      <div class="is-size-3 pb-6 lh0">
        <img @click="getRandomLogoNum()" style="width: 85%" :src="logos[logonum]" alt="Fanny Laulaigne">
      </div>
      <p class="is-size-3">
        <router-link id="hov" class="has-text-white" to="/about">à propos</router-link>
      </p>
      <div v-for="year in this.years" :key="year"><p class="pt-4 is-size-3">{{year}}</p>
        <ul v-for="post in posts.slice().reverse()" :key="post.titre" class="mt-1">
          <li v-if="post.year == year">
            <a id="hov" class="has-text-white" @click="scrollMeTo(`${post.id}`)">{{post.titre}}
              <p class="blue" style="display: inline-block">{{post.sousTitre}}</p>
            </a>
          </li>
        </ul>
      </div>
      <div style="padding-top: 150px;">
        <a href="https://toutdoitdisparaitreclub.tumblr.com/" target="_blank">
          <img src="../assets/img/JigglyPuff01.gif" alt="tumblr link">
        </a>
      </div>
    </aside>
    <!-- CONTENT -->
    <div class="column content"> 
      <div class="is-hidden-tablet is-size-3 pb-6 lh0 has-text-left">
        <img @click="getRandomLogoNum()" :src="logos[logonum]" alt="Logo">
      </div>
      <div  v-for="post in posts.slice().reverse()" :key="post.titre">
        <a :ref="`${post.id}`"></a>
        <div class=" columns is-multiline is-left">
            <h1 class="column is-half has-text-left has-text-white mb-0 pb-1 notBoldTitle">→ {{post.titre}} <p class="is-size-4 blue" style="display: inline-block">{{post.sousTitre}}</p></h1>
            <div class="column is-half has-text-left"></div>
            <div class="column is-half has-text-left" v-html="post.contenuTexte.html"></div>
            <div class="column is-half has-text-left">{{post.notes}}
              <a target="_blank" id="hov" class="blue" v-for="(name, index) in post.collabNom" :key="index" :href="post.collabLien[index]"> <br> {{name}} </a> 
            </div>
        </div>

        <div class="columns is-multiline is-centered mb-5">
          <div class="column pt-2 px-3" v-for="(image, index) in post.images"  :key="index" v-bind:class="{'is-full': index == post.images.length - 1 && index % 2 === 0, 'is-half': index !== post.images.length - 1 }">
            <div>
              <img :src="image.url" :alt="`${post.titre}`" v-bind:title="post.titre" />
            </div>
          </div>
        </div>
 
      </div>
    </div>
    </div>
  </div>
  <div class="has-text-left"><p class="is-size-7">© Fanny Laulaigne 2022 <br> Conception du site: Benoit Jacquet</p></div>
</template>

<script>
import { gql } from 'graphql-request';
import { GraphQLClient } from 'graphql-request';

const graphcmsClient = new GraphQLClient(
    'https://api-eu-central-1.graphcms.com/v2/ckwnr0i5k1iwz01z2gcab8cj5/master'
  );
  
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      posts: [],
      loading: true,
      errors: [],
      mobileMenuActive: false,
      years: [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2014, 2013],
      logos: [require('../assets/img/logo/fanny-soleil-01.svg'), 
              require('../assets/img/logo/fanny-soleil-02.svg'),
              require('../assets/img/logo/fanny-soleil-03.svg'),
              require('../assets/img/logo/fanny-soleil-04.svg'),
              require('../assets/img/logo/fanny-soleil-05.svg'),
              require('../assets/img/logo/fanny-soleil-06.svg'),
              require('../assets/img/logo/fanny-soleil-07.svg'),
              require('../assets/img/logo/fanny-soleil-08.svg'),
              ],
      logonum:1
    };
  },
  // GET CMS DATAS
  async created() {
    const data = await graphcmsClient.request(
      gql`
        {
          worksPlr {
            id
            titre
            sousTitre
            contenuTexte {
              html
              markdown
              text
              }
            notes
            year
            collabNom
            collabLien
            images {
              url
              handle
              fileName
              height
              width
              size
              mimeType
            }
          }
        }
      `
    );
    this.errors = data.errors;
    this.loading = false;
    this.posts = data.worksPlr;
    this.posts.sort(function (a, b) {
      return a.year - b.year;
      });
    this.getRandomLogoNum();
  },
  methods: {
    //SCROLL TO ELEMENT WITH DYNAMIC ID
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    toggleMobileMenu: function(event){
       this.mobileMenuActive = !this.mobileMenuActive;
      },
    getRandomLogoNum() {
      this.logonum = Math.floor(Math.random() * 8)
    }
  },
};
</script>
<style>

.menu {
  position: sticky;
  display: inline-block;
  vertical-align: top;
  max-height: 100vh;
  overflow-y: auto;
  width: 280px;
  top: 0;
  bottom: 0;
  padding: 30px;
  padding-top: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.menu::-webkit-scrollbar { 
    display: none;
}

.content {
  display: inline-block;
}

.pad {
  padding: 0px;
}

.lh0 {
  line-height: 110%;
}

#hov:hover {
  background-color: black;
  color:  #FF46E6 !important;
}

.blue {
  color: #00FA00;
  /* color: blue; */
}

.notBoldTitle {
  font-weight: normal !important;
  line-height: 1.125;
}
</style>